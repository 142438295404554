import { Threshold } from '../../components/pages/SensorDetailPage/components/AlertGrid';
import { MeasureTypeEnum, SensorRawFamilyEnum } from '../enums/feelbat.enum';
import { Project, Sensor } from '../types/feelbat';
import { getRandomColor } from './color';

/**
 * List of sensor families considered as mechanical sensors.
 * @type {SensorRawFamilyEnum[]}
 */
const mechanicalSensorFamilies: SensorRawFamilyEnum[] = [
  SensorRawFamilyEnum.JC1,
  SensorRawFamilyEnum.JC2,
];

/**
 * Represents sensor families that require a coefficient.
 * @type {SensorRawFamilyEnum[]}
 */
const coefficientRequiredFamilies: SensorRawFamilyEnum[] = [
  SensorRawFamilyEnum.LMINI,
  SensorRawFamilyEnum.DELTA,
  SensorRawFamilyEnum.DELTR,
  SensorRawFamilyEnum.DELTX_L100,
];

/**
 * Determines if a sensor requires a coefficient based on its family.
 *
 * @param {Sensor} sensor - The sensor for which to check the coefficient requirement.
 * @returns {boolean} A boolean indicating whether a coefficient is needed for the sensor.
 */
export const isCoefficientNeeded = (sensor: Sensor): boolean =>
  coefficientRequiredFamilies.includes(sensor?.family as SensorRawFamilyEnum);

/**
 * Checks if a given sensor family belongs to mechanical sensor families (JC1 or JC2).
 *
 * @param {string | undefined} sensorFamily - The sensor family to be checked.
 * @returns {boolean} Returns true if the sensor family is a mechanical sensor (JC1 or JC2), otherwise false.
 */
export const isMechanicalSensor = (sensorFamily: string | undefined): boolean =>
  sensorFamily !== undefined &&
  mechanicalSensorFamilies.includes(sensorFamily as SensorRawFamilyEnum);

/**
 * Retrieves the unit of measurement for a given type of sensor.
 * @param type - The type of measurement.
 * @returns The unit of measurement.
 */
export const getSensorUnit = (type: MeasureTypeEnum) => {
  switch (type) {
    case MeasureTypeEnum.PRESSURE:
      return 'Pa';
    case MeasureTypeEnum.HUMIDITY:
      return '%';
    case MeasureTypeEnum.PHOTORESISTOR:
    case MeasureTypeEnum.WALL_HUMIDITY:
      return '%';
    case MeasureTypeEnum.CO2:
      return 'ppm';
    case MeasureTypeEnum.AIR_QUALITY:
      return '';
    case MeasureTypeEnum.TEMPERATURE:
      return '°C';
    case MeasureTypeEnum.WALL_TEMPERATURE:
      return '°C';
    case MeasureTypeEnum.ANGLEX:
      return '°';
    case MeasureTypeEnum.ANGLEY:
      return '°';
    case MeasureTypeEnum.CRACKMOVEMENT:
      return 'mm';
    case MeasureTypeEnum.GROUND_WATER_LEVEL:
      return 'mm';
    default:
      return 'mm';
  }
};

/**
 * Gets the color code corresponding to a metric type.
 * @param type - The type of metric (e.g., temperature, angle, mismatch).
 * @param isOriginal - Indicates whether to return a predefined color for original metrics or a random color.
 * @returns The color code as a hexadecimal string.
 */
export const getMetricColor = (
  type: MeasureTypeEnum,
  isOriginal: boolean
): string => {
  if (isOriginal) {
    switch (type) {
      case MeasureTypeEnum.TEMPERATURE:
        return '#E85319';
      case MeasureTypeEnum.ANGLEY:
      case MeasureTypeEnum.MISMATCH:
        return '#39B4A3';
      default:
        return '#2468C1';
    }
  } else {
    return getRandomColor();
  }
};

/**
 * Returns an array of MeasureTypeEnum values based on the given SensorRawFamilyEnum type.
 * @param type The SensorRawFamilyEnum type.
 * @returns An array of MeasureTypeEnum values corresponding to the given type.
 */
export const getSensorMetric = (
  type: SensorRawFamilyEnum
): MeasureTypeEnum[] => {
  switch (type) {
    case SensorRawFamilyEnum.DELTA:
      return [MeasureTypeEnum.CRACKMOVEMENT, MeasureTypeEnum.TEMPERATURE];
    case SensorRawFamilyEnum.DELTR:
      return [
        MeasureTypeEnum.ANGLEY,
        MeasureTypeEnum.ANGLEX,
        MeasureTypeEnum.TEMPERATURE,
      ];
    case SensorRawFamilyEnum.DELTXP:
      return [MeasureTypeEnum.GROUND_WATER_LEVEL, MeasureTypeEnum.TEMPERATURE];
    case SensorRawFamilyEnum.DELTX_L100:
      return [MeasureTypeEnum.CRACKMOVEMENT, MeasureTypeEnum.TEMPERATURE];
    case SensorRawFamilyEnum.DELHM:
      return [
        MeasureTypeEnum.HUMIDITY,
        MeasureTypeEnum.WALL_HUMIDITY,
        MeasureTypeEnum.WALL_TEMPERATURE,
        MeasureTypeEnum.TEMPERATURE,
      ];
    case SensorRawFamilyEnum.JC1:
      return [MeasureTypeEnum.CRACKMOVEMENT, MeasureTypeEnum.TEMPERATURE];
    case SensorRawFamilyEnum.JC2:
      return [
        MeasureTypeEnum.CUTTING,
        MeasureTypeEnum.MISMATCH,
        MeasureTypeEnum.TEMPERATURE,
      ];
    case SensorRawFamilyEnum.DELTX_H:
      return [MeasureTypeEnum.HUMIDITY, MeasureTypeEnum.TEMPERATURE];
    case SensorRawFamilyEnum.LMINI:
      return [
        MeasureTypeEnum.CRACKMOVEMENT,
        MeasureTypeEnum.TEMPERATURE,
        MeasureTypeEnum.PHOTORESISTOR,
      ];
    default:
      return [];
  }
};

/**
 * Retrieves all sensors from a given project.
 *
 * This function extracts and returns a flat array of sensors from the provided project.
 * If the project is null or does not contain any modules, it returns an empty array.
 *
 * @param {Project | null} project - The project object from which to retrieve sensors. Can be null.
 *
 * @returns {Array<Sensor>} - An array of sensors extracted from the project. Returns an empty array if the project is null or contains no sensors.
 */
export const getProjectSensors = (project: Project | null): Array<Sensor> => {
  if (!project || !project.tmodules) {
    return [];
  }

  return project.tmodules.flatMap((module) => module.sensors || []);
};

/**
 * Retrieves the threshold configuration for a given sensor.
 *
 * This function first checks if the sensor itself has any thresholds defined.
 * If so, it returns those thresholds. If not, it checks the project's
 * thresholds to find a threshold configuration matching the sensor's family.
 * If a match is found, it returns that threshold; otherwise, it returns `null`.
 *
 * @param {Sensor} sensor - The sensor object for which to retrieve thresholds.
 * @param {Project} project - The project object containing potential fallback thresholds.
 * @returns {Threshold | null} The matching threshold configuration, or `null` if none is found.
 */
export const getSensorThresholds = (
  sensor: Sensor,
  project: Project
): Threshold | null => {
  if (sensor.threshold) {
    return sensor.threshold;
  }

  if (project.thresholds) {
    const threshold = project.thresholds.find(
      (obj) => obj.type === sensor.family
    );
    return threshold ?? null;
  }

  return null;
};
